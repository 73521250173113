import React, { useEffect, useRef, useState } from 'react'
import {
    Label,
    FormGroup
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Spin,
    Progress,
    message,
    Form,
    DatePicker,
    TimePicker
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import api from '../../services/api';

import { useParams } from 'react-router';

const { TextArea } = Input;

const antIcon = (
<LoadingOutlined
    style={{
    fontSize: 24,
    }}
    spin
/>
);

const OportunityCreate = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading,setloading] = useState();
    const [uploading,setUploading] = useState(false);
    const [percentage,setPercentage] = useState(0);
    const [video_info,set_video_info] = useState({});
    const [imageUrl,setImageUrl] = useState('');
    const [imageFile,setImageFile] = useState(null);
    const [categorias,set_categorias] = useState([]);
    const [date,set_date] = useState(new Date);
    const [empresas,set_empresas] = useState("");
    const [typess,set_typess] = useState([
        {
            value: "Prencial",
            label: "Prencial"
        },
        {
            value: "Full Time",
            label: "Full Time"
        },
        {
            value: "Part Time",
            label: "Part Time"
        },
        {
            value: "Remoto",
            label: "Remoto"
        }
    ]);

    const imageRef = useRef();
    const params = useParams();

    const id = params.id;

    let navigate = useNavigate();

    const fileRef = useRef();

    const load = async () => {
        try{
            const response_types = await api.get('/oportunity/categorias');
            const response_empresas = await api.get('/oportunity/empresas', {
				params: { 
					search: "",
					orderBy: "desc"
				}
			});
            const cats = response_types.data.data;
            const empresa = response_empresas.data.data;
            set_categorias(cats.map((item) => ({
				value: item.id,
				label: item.name
			})));
            set_empresas(empresa.map((item) => ({
				value: item.id,
				label: item.nome
			})));
        }catch(error){
            console.log(error);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinish = async (values) => {
        setloading(true);
        if(!(imageUrl)){
            return messageApi.open({
                type: 'error',
                content: 'Selecione uma imagem!',
                className: 'custom-class',
                style: {
                    marginTop: '20vh'
                }
            });
        }
        const Form = new FormData();
        Form.append('title',values.title);
        Form.append('descricao',values.descricao);
        Form.append('category',values.category);
        Form.append('tipo',values.tipo);
        Form.append('link',values.link);
        Form.append('status', values.visibilidade);
        Form.append('qualifications',values.qualifications);
        Form.append('qualifications_selected',values.qualifications_selected);
        Form.append('responsibilities',values.responsibilities);
        Form.append('empresa',values.empresa);
        Form.append('date', new Date(date));

        if(imageFile){
            Form.append('image',imageFile[0]);
            values.image = imageFile[0];
        }

        try{
            const response = await api.post('/oportunity/create', Form);
            const data = response.data.data;
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
            navigate(`/admin/webinars/view/${data}`);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageFile(file);
            setImageUrl(url);
        });
    };

    useEffect(() => {
        load();
    },[]);

    return (
        <div className='bg-white m-5 p-7'>
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Criar oportunidade</h3>
            <Form
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <Row gutter={12}>
                    <Col sm={24}>
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Título</Label>
                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <TextArea rows={14} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Qualificações mínimas</Label>
                                <Form.Item
                                    name="qualifications"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your qualificações mínimas!',
                                        }
                                    ]}
                                >
                                    <TextArea rows={9} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Qualificações preferenciais</Label>
                                <Form.Item
                                    name="qualifications_selected"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your qualificações preferenciais!',
                                        }
                                    ]}
                                >
                                    <TextArea rows={9} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Responsabilidadess</Label>
                                <Form.Item
                                    name="responsibilities"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your qualificações responsabilidades!',
                                        }
                                    ]}
                                >
                                    <TextArea rows={9} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Link de condidatura</Label>
                                <Form.Item
                                    name="link"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your link!',
                                        }
                                    ]}
                                >
                                    <Input placeholder='youtube.com/watch?v=OL8NDU16Ex8'/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Categoria</Label>
                                <Form.Item
                                    name="category"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Categoria!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={categorias}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Empresa</Label>
                                <Form.Item
                                    name="empresa"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your empresa!',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={empresas}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Data de expiração</Label>
                                <Form.Item
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your data!',
                                        }
                                    ]}
                                >
                                    <DatePicker 
                                        style={{width: "100%"}} 
                                        onChange={(date, dateString) => {
                                            set_date(dateString);
                                        }} 
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Tipo</Label>
                                <Form.Item
                                    name="tipo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your tipo!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={typess}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Visibilidade</Label>
                                <Form.Item
                                    name="visibilidade"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Visibilidade!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Activo' },
                                            { value: 0, label: 'Inactivo' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col sm={12}>
                        <div>
                            <div className="w-full mb-3"> {/* conta corporativa*/}
                                <div className='video-background'>
                                    <div onClick={() => imageRef.current.click()} className='bold' style={{display: 'flex',justifyContent: 'center',alignItems: 'center',width: '100%', height: '100%', overflow: 'hidden'}}>
                                        { uploading
                                        ? 
                                        <Spin indicator={antIcon} /> 
                                        : 
                                        <div>
                                            {
                                                imageUrl ? <img style={{width:'100%',height:'100%',objectFit: 'cover'}} src={imageUrl}/> : 'Imagem'
                                            }
                                        </div> }
                                    </div>
                                    <input hidden type={'file'} onChange={handleChangeImage} ref={imageRef}></input>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Button disabled={loading} htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Submit</Button>
            </Form>
        </div>
    )
}

export default OportunityCreate;
