import React, { useEffect, useState } from 'react'
import { 
    Button, 
    Space, 
    Table, 
    Tag, 
    Select,
    Row,
    Col,
    Input,
	message
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
	FolderViewOutlined,
	PlusOutlined
} from '@ant-design/icons';

import image from '../../assets/images/imagebg.png';
import api from '../../services/api';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { BiSolidBookAlt } from "react-icons/bi";

const Library = () => {
	const [data,setData] = useState();
	const [search,setSearch] = useState('');
	const [messageApi, contextHolder] = message.useMessage();
	const [index,setIndex] = useState(1);
	const [orderBy,setOrderBy] = useState('asc');
	const [loading,setLoading] = useState(false);
    const [categorias,setCategorias] = useState([]);
    const [category,set_category] = useState("");
    const [sub_category,set_sub_category] = useState("");
    const [sub_categories,set_sub_categories] = useState([]);
    const [book_type,set_book_type] = useState([]);
    const [type,set_type] = useState("");

    const [pagination, set_pagination] = useState({
        current: 1,
        pageSize: 20
    });
    const [report,set_report] = useState({});

    const load_cat = async () => {
        try{
			const response = await api.get('/library/categorias');
            const response_book_type = await api.get('/library/tipos');
			const cats = response.data.data;
            const data_categorias = [
                {
                    value: "",
                    label: "All"
                }
            ];
            cats.map((item) => {
                if(!item.parent_id){
                    data_categorias.push({
                        value: item.id,
                        label: item.name
                    });
                }
            })
			setCategorias(data_categorias);

            const data_sub = [
                {
                    value: "",
                    label: "All"
                }
            ];
            cats.map((item) => {
                if(item.parent_id){
                    data_sub.push({
                        value: item.id,
                        label: item.name
                    });
                }
            });
            set_sub_categories(data_sub);
            set_book_type(response_book_type.data.data.map((item) => ({
                value: item.id,
                label: item.name
            })));
        }catch(error){
            console.log(error);
        }
    }

	const load = async (data = {...pagination}) => {
		setLoading(true);
		try{
            const response_relatorio = await api.get("/library/relatorio");
            const response = await api.get('/library', {
				params: { 
					search,
					orderBy,
					page: data.current,
                    category,
                    sub_category,
                    type: type
				}
			});
            const rows = response.data.data;
            const relatorio = response_relatorio.data;
			setLoading(false);
			setData(response.data.data);

            set_pagination({
                ...data,
                total: rows.total
            });
            set_report(relatorio);
        }catch(error){
            setLoading(false);
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const handleChange = (value) => {
		setOrderBy(value);
    };

	const destacar = async (id) => {
		setLoading(true);
		const Form = new FormData();
		Form.append('id',id)
		try{
            const response = await api.post('/library/destacar',Form);
			messageApi.open({
				type: 'success',
				content: response.data.message,
				className: 'custom-class',
				style: {
					marginTop: '20vh',
				},
			});
			load();
			setLoading(false);
        }catch(error){
            console.log(error);
			setLoading(false);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	const showPopconfirmDelete = (id) => {
		Swal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

	const handleDelete = async (id) => {
		try{
            const response = await api.delete(`/library/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const filter = () => {
        load();
    }
    
    const columns = [
        {
          title: '',
		  key: 'imagem',
          render: (row) => {
            return(
                <div className='modulo-capa'>
                    <img src={row.imagem ? row.imagem : image}></img>
                </div>
            )
          }
        },
        {
			title: 'Título',
			dataIndex: 'title',
			key: 'title'
        },
        {
			title: 'Descrição',
			dataIndex: 'descricao',
			key: 'descricao',
			render: (row) => {
				return(
					`${row.substr(0,45)}...`
				)
			}
        },
        {
			title: 'Visibilidade',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        },
        {
			title: 'Categoria',
			dataIndex: 'category_name',
			key: 'category_name'
        },
        {
			title: 'Tipo',
			dataIndex: 'type_name',
			key: 'type_name'
        },
        {
            title: 'Data de criação',
            dataIndex: 'created_at',
            key: 'created_at',
			render: (row) => {
				return(
					moment(row).format('DD-MM-YYYY HH:mm')
				)
			}
        },
        {
			title: 'Action',
			key: 'action',
			render: (row) => {
				return (
					<Space size="middle">
						<Link to={`/admin/library/book/view/${row.id}`}>
							<Button shape="circle">
								<FolderViewOutlined/>
							</Button>
						</Link>
						<Link to={`/admin/library/book/edit/${row.id}`}>
							<Button shape="circle">
								<EditOutlined />
							</Button>
						</Link>
						<Button onClick={() => showPopconfirmDelete(row.id)} danger shape="circle">
							<DeleteOutlined />
						</Button>
					{ row.highlight == 1 ? 
						<Button onClick={() => destacar(row.id)} className='bg-succuss-sflix text-white'>
							Remover destaque
						</Button> : <Button onClick={() => destacar(row.id)} className='bg-succuss-sflix text-white'>
							Destacar
						</Button> }
					</Space>
				)
			}
        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        load(pagination);
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== pagination.pageSize) {
          setData([]);
        }
    };

	useEffect(() => {
		load();
        load_cat();
	},[]);

    return (
        <div className=''>
            <div className='plaform-cards m-5'>
                <div className='card-flix-tp'>
                    <div className='card-inner-cont-fl'>
                        <div className='card-f-left'>
                            <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{report.active}</h3>
                            <div className='text-ttl-card'>Livros Activos</div>
                        </div>
                        <div className='card-f-right'>
                            <div className='icon-ilustration' style={{backgroundColor: 'rgba(15, 169, 102, 0.259'}}>
                                <BiSolidBookAlt size={48}/>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5' style={{height: 5, backgroundColor: '#0fa966'}}></div>
                </div>
                <div className='card-flix-tp'>
                    <div className='card-inner-cont-fl'>
                        <div className='card-f-left'>
                            <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{report.innactive}</h3>
                            <div className='text-ttl-card'>Livros Inactivos</div>
                        </div>
                        <div className='card-f-right'>
                            <div className='icon-ilustration' style={{backgroundColor: 'rgba(15, 169, 102, 0.259'}}>
                                <BiSolidBookAlt size={48}/>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5' style={{height: 5, backgroundColor: '#0fa966'}}></div>
                </div>
            </div>

            <div className='m-5 mt-3 p-4 mb-4 bg-white'>
                <Row gutter={12}>
                    <Col span={5}>
                        <Input onChange={(e) => {
                            setSearch(e.target.value);
                        }} placeholder="Pesquisar" />
                    </Col>
                    <Col span={5}>
                        <Select
                            style={{ width: '100%' }}
                            className=""
                            placeholder="Categoria"
                            onChange={(v) => {
                                set_category(v);
                            }}
                            options={categorias}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            style={{ width: '100%' }}
                            className=""
                            placeholder="Sub Categoria"
                            onChange={(v) => {
                                set_sub_category(v);
                            }}
                            options={sub_categories}
                        />
                    </Col>
                    <Col span={3}>
                        <Select
                            placeholder="Ordenar por"
                            style={{width: "100%"}}
                            className=""
                            onChange={handleChange}
                            options={[
                                { value: 'desc', label: 'Recentes' },
                                { value: 'asc', label: 'Antigos' },
                            ]}
                        />
                    </Col>
                    <Col span={3}>
                        <Select
                            placeholder="Tipo"
                            style={{width: "100%"}}
                            className=""
                            onChange={(v) => {
                                set_type(v);
                            }}
                            options={book_type}
                        />
                    </Col>
                    <Col span={3}>
                        <Button onClick={() => filter()} className='bg-succuss-sflix' style={{display: "flex", alignItems: "center", gap: 4}} type="primary" success>
                            <FaSearch size={10}/> <span>Pesquisar</span>
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
                {contextHolder}
                { loading ? <div className='loader-flix-cont'>
                    <div
                        className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span>
                    </div>
                </div> : null }
                <div className='flex-ttp-items mb-4'>
                    <h3 className='header-sflix text-dark mb-3'>Biblioteca</h3>
                    <Link to={`/admin/library/book/create`}>
                        <Button className='bg-succuss-sflix' type="primary" success>Adicionar</Button>
                    </Link>
                </div>
                
                <Table 
                    columns={columns} 
                    dataSource={data} 
                    onChange={handleTableChange}
                    ///rowKey={(record) => console.log(record)}
                    pagination={pagination}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default Library;