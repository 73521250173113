import React, { useEffect, useState } from 'react'
import { Button, Space, Table, Tag, message } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import api from '../../../services/api';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const OportunityCategories = () => {
	const MySwal = withReactContent(Swal);
	const [messageApi, contextHolder] = message.useMessage();
	const [data,setData] = useState([]);
	const [loading,setLoading] = useState(true);

	const load = async () => {
		try{
			const response_types = await api.get('/oportunity/categorias');
			const cats = response_types.data.data;
			setData(cats);
			setLoading(false);
        }catch(error){
			setLoading(false);
            console.log(error);
        }
	}

	const handleDelete = async (id) => {
		setLoading(true);
		try{
            const response = await api.delete(`/oportunity/category/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
			setLoading(false);
        }catch(error){
			setLoading(false);
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	const showPopconfirmDelete = (id) => {
		MySwal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

    const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name'
        },
		{
			title: 'Sub Categoria',
			dataIndex: 'categoria',
			key: 'categorias'
		  },
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
			  if(row){
				  return (
					  <Tag color={'green'}>
						  Activo
					  </Tag>
				  );
			  }else{
				  return (
					  <Tag color={'volcano'}>
							Inactivo
					  </Tag>
				  );
			  }
			}
		},
        {
          title: 'Ação',
          key: 'action',
          render: (row) => (
            <Space size="middle">
              	<Link to={`/admin/oportunity/categories/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>
				<Button danger shape="circle" onClick={() => handleDelete(row.id)}>
					<DeleteOutlined />
				</Button>
            </Space>
			
          ),
        },
    ];

	useEffect(() => {
		load();
	},[]);

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
			{contextHolder}
			{ loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            <div className='flex-ttp-items mb-4'>
                <h3 className='header-sflix text-dark'>Categorias</h3>
                <Link to={`/admin/oportunity/categories/create`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
            />
        </div>
    )
}

export default OportunityCategories;