import React, { useEffect, useState } from 'react'
import { useCookies } from "react-cookie";

import logo from '../../assets/images/susehub.jpeg';

import {
    Label
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Form
} from 'antd';

import api from '../../services/api';

const Login = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [loading,setloading] = useState(false);
    const [error,setError] = useState(false);
    const [message,setMessage] = useState('');
    const [browserInfo,setBrowserInfo] = useState({});

    const load = async () => {
        fetch('https://api.db-ip.com/v2/free/self')
        .then((res) => res.json())
        .then((data) => {
            setBrowserInfo(data);
        })
        .catch((err) => {
            console.log(err.message);
        });
    }

    const onFinish = async (values) => {
        console.log('finish',values)
        values.browser = browserInfo;
        setloading(true);
        setError(false);
        try{
            const response = await api.post('/auth/login', values);
            const data = response.data.data;

            if(data.user_type != 1){
                await api.post('/auth/logout');
                removeCookie('user',{ path: '/' });
                setError(true);
                setMessage('Sem permissão')
            }

            if(data){
                setCookie("user", data.userId, {
                    path: "/",
                    maxAge: 28800,
                });
            }
            setloading(false);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                setError(true);
                setMessage(error_response.data.message);
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('failed',errorInfo)
    }

    useEffect(() => {
        load();
    },[]);

    return (
        <div className='login-wrapper '>
            <div className='mb-3' style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <div className='navbar-wi-logo'>
                    <img className='logo' src={logo}></img>
                </div>
            </div>
            <div className='login-body'>
                <div className='login-content'>
                    <div className=''>
                        <h3 className='header-login'>Login Admin</h3>
                        <Form 
                            className='mt-6'
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <div sm={12} className="w-full">
                                <Row className='flex-col'>
                                    <div className="w-full">
                                        <Label className='text-white'>E-mail</Label>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email!',
                                                }
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="w-full mt-3">
                                        <Label className='text-white'>Password</Label>
                                        <Form.Item
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    }
                                                ]}
                                            >
                                                <Input.Password />
                                        </Form.Item>
                                    </div>
                                </Row>
                                <Button 
                                    htmlType="submit" 
                                    type="primary" 
                                    className='mt-4 bg-succuss-sflix w-full text-white' 
                                    success
                                    disabled={loading}
                                >
                                    { loading ? <>
                                        <div
                                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status">
                                            <span
                                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >Loading...</span>
                                        </div>
                                        <span className='ml-2'>Loading...</span>
                                    </> : 'Submit' }
                                </Button>
                            </div>
                            { 
                                error 
                                ? 
                                <div className='error text-danger mt-3'>
                                    {message}
                                </div> 
                                : null 
                            }
                            <div className='mt-10 pt-10 text-center'>
                                <p className='text-white'>Copyright © 2023 <a href='' className=''>SuseHub.</a> All rights reserved.</p>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login