import React, { useEffect, useRef, useState } from 'react'
import {
    Label,
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Form,
    message
} from 'antd';
import { useParams } from 'react-router';
import api from '../../../services/api';
import image from '../../../assets/images/imagebg.png';
import { Link } from "react-router-dom";

const { TextArea } = Input;

const EmpresaView = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const [imageFile,setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(image);
    const [loading,setloading] = useState();
    const [Types,set_types] = useState([]);
    const [data,setData] = useState({});

    const imageRef = useRef();
    const params = useParams();
    const id = params.id;

    const load = async () => {
        try{
            const response_users = await api.get(`/users`,{
                params: {
					search: "",
					tipo: ""
				}
            });
            const response_empresas = await api.get(`/oportunity/empresas/${id}`)
            const users = response_users.data.data;
            const data = response_empresas.data.data;
            set_types(users.map((item) => ({
                value: item.id,
                label: `${item.nome} ${item.apelido}`,
            })));

            if(data.imagem){
                setImageUrl(data.imagem);
            }

            form.setFieldsValue({
                nome: data.nome
            });
            form.setFieldsValue({
                endereco: data.endereco
            });
            form.setFieldsValue({
                nuit: data.nuit
            });
            form.setFieldsValue({
                email: data.email
            });
            form.setFieldsValue({
                contacto: data.contacto
            });
            form.setFieldsValue({
                descricao: data.descricao
            });
            form.setFieldsValue({
                related_user: data.user
            });
            form.setFieldsValue({
                status: data.status
            });

        }catch(error){
            console.log(error);
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageFile(file);
            setImageUrl(url);
        });
    };

    const onFinish = async (values) => {
        setloading(true);
        console.log(values);
        const Form = new FormData();
        Form.append('id',id);
        Form.append('nome',values.nome);
        Form.append('endereco',values.endereco);
        Form.append('email',values.email);
        Form.append('contacto',values.contacto);
        Form.append('descricao',values.descricao);
        Form.append('related_user',values.related_user);
        Form.append('nuit',values.nuit);
        Form.append('status',values.status);

        if(imageFile){
            Form.append('image',imageFile[0]);
            values.image = imageFile[0];
        }

        try{
            const response = await api.post('/oportunity/empresa', Form);
            const data = response.data.data;
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    useEffect(() => {
        load()
    },[]);

    return (
        
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            { loading ? <div className='loader-flix-cont'>
                <div
                    class="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Editar Empresa</h3>
            <div className='profile-image-container mb-5'>
                <div onClick={() => {
                    imageRef.current.click();
                }} className='user-profile-image-container'>
                    <img src={imageUrl}></img>
                </div>
                <input hidden type={'file'} onChange={handleChangeImage} ref={imageRef}></input>
            </div>
            <Form
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Nome</Label>
                                <Form.Item
                                    name="nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your nome!',
                                        }
                                    ]}
                                >
                                    <Input
                                    disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Endereço</Label>
                                <Form.Item
                                    name="endereco"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your endereço!',
                                        }
                                    ]}
                                >
                                    <Input
                                    disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Nuit</Label>
                                <Form.Item
                                    name="nuit"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your nuit!',
                                        }
                                    ]}
                                >
                                    <Input
                                    disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Email</Label>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        }
                                    ]}
                                >
                                    <Input
                                    disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Contacto</Label>
                                <Form.Item
                                    name="contacto"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your contacto!',
                                        }
                                    ]}
                                >
                                    <Input
                                    disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            message: 'Please input your biografia!',
                                        }
                                    ]}
                                >
                                    <TextArea
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Usuário Relacionado</Label>
                                <Form.Item
                                    name="related_user"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your usuário!',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={Types}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Status</Label>
                                <Form.Item
                                    name="status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your status!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Active' },
                                            { value: 0, label: 'Inactive' }
                                        ]}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                </div>
            </Form>
            <Link to={`/admin/oportunity/empresas/edit/${id}`}>
                <Button className='mt-4 bg-succuss-sflix' type="primary" success>Editar</Button>
            </Link>
        </div>
    )
}

export default EmpresaView;
