import React from 'react'
import {
    Label
} from "reactstrap";

import { 
    Button,
    Input,
    Row,
    Select,
    Form,
    message
} from 'antd';

import api from '../../../services/api';

const UserTypesCreate = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinish = async (values) => {
        try{
            const response = await api.post(`/user/types/create`,values);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    return (
        <div className='bg-white m-5 p-7'>
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Tipo de usuário</h3>
            <Form
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Nome</Label>
                                <Form.Item
                                    name="nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your nome!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Status</Label>
                                <Form.Item
                                    name="status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your status!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Active' },
                                            { value: 0, label: 'Inactive' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                </div>
                <Button htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Submit</Button>
            </Form>
        </div>
    )
}

export default UserTypesCreate;
