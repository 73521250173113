import React, { useEffect, useState } from 'react'
import { 
    Button, 
    Space, 
    Table, 
    Tag,
    Row,
    Col,
    Input,
	message,
	Select
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    FolderViewOutlined
  } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useParams } from 'react-router';
import api from '../../services/api';
import Swal from 'sweetalert2'
import { FaSearch } from "react-icons/fa";

const Oportunity = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [data,setData] = useState();
	const [search,setSearch] = useState('');
	const [index,setIndex] = useState(1);
	const [loading,setLoading] = useState(false);

	const params = useParams();
    const id = params.id;

	const [orderBy,setOrderBy] = useState('desc');
	const [categorias,set_categorias] = useState([]);
	const [category,set_category] = useState("");
	const [pagination, set_pagination] = useState({
        current: 1,
        pageSize: 20
    });

	const load_others = async () => {
		try{
			const response_types = await api.get('/oportunity/categorias');
			const cats = response_types.data.data;
			set_categorias(cats.map((item) => ({
				value: item.id,
				label: item.name
			})));
		}catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	const load = async (data = {...pagination}) => {
		setLoading(true);
		try{
            const response = await api.get('/oportunity', {
				params: { 
					search,
					orderBy,
					index,
					page: data.current,
                    category,
				}
			});
			const rows = response.data.data;
			setData(response.data.data);
            set_pagination({
                ...data,
                total: rows.total
            });
			setLoading(false);
        }catch(error){
            console.log(error);
			setLoading(false);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	const filter = () => {
		load();
	}

	const handleChange = (e) => {
		const value = e.target.value;
		setSearch(value);
    };

	useEffect(() => {
		load();
	},[search,index]);


	const showPopconfirmDelete = (id) => {
		Swal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

	const handleDelete = async (id) => {
		try{
            const response = await api.delete(`/oportunity/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const columns = [
        {
            title: 'id',
            key: 'id',
			dataIndex: 'id'
        },
        {
			title: 'Título',
			dataIndex: 'title',
			key: 'title'
        },
        {
			title: 'Descrição',
			dataIndex: 'descricao',
			key: 'descricao',
			render: (row) => {
				return(
					`${row.substr(0,45)}...`
				)
			}
        },
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        },
        {
			title: 'Action',
			key: 'action',
			render: (row) => (
				<Space size="middle">
					<Link to={`/admin/oportunity/edit/${row.id}`}>
						<Button shape="circle">
							<EditOutlined />
						</Button>
					</Link>
					<Link to={`/admin/oportunity/view/${row.id}`}>
						<Button shape="circle">
							<FolderViewOutlined/>
						</Button>
					</Link>
					<Button onClick={() => showPopconfirmDelete(row.id)} danger shape="circle">
						<DeleteOutlined />
					</Button>

					{ row.destaque == 1 ? 
						<Button onClick={() => destacar(row.id)} className='bg-succuss-sflix text-white'>
							Remover destaque
						</Button> : <Button onClick={() => destacar(row.id)} className='bg-succuss-sflix text-white'>
							Destacar
						</Button> }
				</Space>
			)
        },
    ];

	const handleTableChange = (pagination, filters, sorter) => {
        load(pagination);
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== pagination.pageSize) {
          setData([]);
        }
    };

	const destacar = async (id) => {
		setLoading(true);
		const Form = new FormData();
		Form.append('id',id)
		try{
            const response = await api.post('/oportunity/destacar',Form);
			messageApi.open({
				type: 'success',
				content: response.data.message,
				className: 'custom-class',
				style: {
					marginTop: '20vh',
				},
			});
			load();
			setLoading(false);
        }catch(error){
            console.log(error);
			setLoading(false);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	useEffect(() => {
		load();
		load_others();
	},[]);

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
			{contextHolder}
			{ loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
			<div className='flex-ttp-items mb-4'>
				<h3 className='header-sflix text-dark mb-3'>Oportunidades</h3>
                <Link to={`/admin/oportunity/create`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <div className='mt-3 mb-4'>
                <Row gutter={8}>
                    <Col span={8}>
                        <Input onChange={handleChange} placeholder="Pesquisar" />
                    </Col>
					<Col span={6}>
                        <Select
                            placeholder="Categoria"
                            style={{width: "100%"}}
                            className=""
                            onChange={(v) => {
								set_category(v);
							}}
                            options={categorias}
                        />
                    </Col>
					<Col span={3}>
                        <Button onClick={() => filter()} className='bg-succuss-sflix' style={{display: "flex", alignItems: "center", gap: 4}} type="primary" success>
                            <FaSearch size={10}/> <span>Pesquisar</span>
                        </Button>
                    </Col>
                </Row>
            </div>
            <Table 
                columns={columns} 
				dataSource={data} 
				onChange={handleTableChange}
				///rowKey={(record) => console.log(record)}
				pagination={pagination}
				loading={loading}
            />
        </div>
    )
}

export default Oportunity;