import React, { useEffect, useRef, useState } from 'react'
import logo from '../assets/images/susehub.jpeg';
import { Link } from 'react-router-dom';
import * as te from 'tw-elements';

import { BiSolidBookAlt, BiPodcast, BiNetworkChart } from "react-icons/bi";
import { SiGooglemeet } from "react-icons/si";
import { GiTeacher } from "react-icons/gi";
import { ImQuotesLeft } from "react-icons/im";
import { MdWork } from "react-icons/md";

const LeftNav = () => {
    const [profile,setProfile] = useState('');
    const dropdown_ref = useRef();

    return (
        <div className='navbar-container-left pb-5'>
            <nav className=''>
                <div className='logo-admin'>
                    <img src={logo}></img>
                </div>
                <ul className='nav-options-list'>
                    <li>
                        <Link to={'/admin/dashboard'} className='active-d'>
                            <div className='icon-nav-opt'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M182.152 546q-28.092 0-48.111-20.019-20.019-20.02-20.019-48.351V278.152q0-28.092 20.019-48.231 20.019-20.139 48.111-20.139h199.717q28.092 0 48.112 20.139Q450 250.06 450 278.152V477.63q0 28.331-20.019 48.351Q409.961 546 381.869 546H182.152Zm0 395.978q-28.092 0-48.111-20.019t-20.019-48.111V674.131q0-28.092 20.019-48.112Q154.06 606 182.152 606h199.717q28.092 0 48.112 20.019Q450 646.039 450 674.131v199.717q0 28.092-20.019 48.111-20.02 20.019-48.112 20.019H182.152ZM578.37 546q-28.331 0-48.351-20.019Q510 505.961 510 477.63V278.152q0-28.092 20.019-48.231 20.02-20.139 48.351-20.139h199.478q28.092 0 48.231 20.139 20.139 20.139 20.139 48.231V477.63q0 28.331-20.139 48.351Q805.94 546 777.848 546H578.37Zm0 395.978q-28.331 0-48.351-20.019Q510 901.94 510 873.848V674.131q0-28.092 20.019-48.112Q550.039 606 578.37 606h199.478q28.092 0 48.231 20.019 20.139 20.02 20.139 48.112v199.717q0 28.092-20.139 48.111t-48.231 20.019H578.37Z"/></svg>
                            </div>
                            <div className='text-option-nav'>
                                Dashboard
                            </div>
                            <div className='drop-arrow'></div>
                        </Link>
                    </li>
                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#userdrop"
                        role="button"
                        aria-expanded="false"
                        aria-controls="userdrop"
                        >
                            <div className='icon-nav-opt'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M66.565 905.087q-14.674 0-24.37-9.696-9.695-9.695-9.695-24.369V804.63q0-36.673 18.837-66.489 18.837-29.815 52.511-44.771 73.478-32 132.695-46.24 59.218-14.239 121.935-14.239 63.196 0 121.794 14.12 58.598 14.119 132.076 46.359 33.674 14.717 53.011 44.532 19.337 29.815 19.337 66.728v66.392q0 14.674-9.696 24.369-9.696 9.696-24.37 9.696H66.565Zm656.848 0q10.478-2.717 17.196-12.293 6.717-9.576 6.717-23.772v-63.913q0-63.479-31.88-104.935-31.881-41.457-84.359-69.087 67.087 8.239 126.533 23.5 59.445 15.261 96.967 34.783 33.717 19 53.315 48.913 19.598 29.913 19.598 67.543v65.196q0 14.674-9.696 24.369-9.695 9.696-24.369 9.696H723.413ZM358.478 571.891q-67.435 0-110.152-42.717-42.717-42.718-42.717-110.392 0-67.434 42.717-110.152 42.717-42.717 110.152-42.717T468.75 308.63q42.837 42.718 42.837 110.152 0 67.674-42.837 110.392-42.837 42.717-110.272 42.717Zm368.848-153.109q0 67.435-42.837 110.272t-110.271 42.837q-11.24 0-24.5-1.619-13.261-1.62-24.979-5.859 24.479-25.478 36.979-62.576t12.5-83.055q0-45.717-12.5-80.934-12.5-35.218-36.979-64.457 11.239-3.239 24.62-5.359 13.38-2.119 24.859-2.119 67.434 0 110.271 42.837t42.837 110.032Z"/></svg>
                            </div>
                            <div className='text-option-nav'>
                                Usuários
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="userdrop" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/user/create'} className=''>
                                        Adicionar
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/users`} className=''>
                                        Registrados
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/users/type`} className=''>
                                        Tipo Usuario
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#library"
                        role="button"
                        aria-expanded="false"
                        aria-controls="library"
                        >
                            <div className='icon-nav-opt'>
                                <BiSolidBookAlt size={15}/>
                            </div>
                            <div className='text-option-nav'>
                                Biblioteca
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="library" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/library/book/create'} className=''>
                                        Adicionar
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/library`} className=''>
                                        Registrados
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/admin/library/categories'} className=''>
                                        Categorias
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/admin/library/tipos'} className=''>
                                        Tipo
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#videodrop"
                        role="button"
                        aria-expanded="false"
                        aria-controls="videodrop"
                        >
                            <div className='icon-nav-opt'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m438.022 713.565 167.869-109.217q8.196-5.478 12.174-12.435 3.978-6.956 3.978-15.913t-3.978-15.913q-3.978-6.957-12.174-12.435L438.022 438.435q-17.152-11.196-34.805-1.5-17.652 9.695-17.652 30.087v217.956q0 20.631 17.652 30.207 17.653 9.576 34.805-1.62Zm-255.87 228.413q-27.587 0-47.859-20.271-20.271-20.272-20.271-47.859V278.152q0-27.587 20.271-47.978 20.272-20.392 47.859-20.392h595.696q27.587 0 47.978 20.392 20.392 20.391 20.392 47.978v595.696q0 27.587-20.392 47.859-20.391 20.271-47.978 20.271H182.152Z"/></svg>
                            </div>
                            <div className='text-option-nav'>
                                Cursos
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="videodrop" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/cursos/create'} className=''>
                                        Adicionar Curso
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/cursos`} className=''>
                                        Cursos
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/admin/cursos/categorias'} className=''>
                                        Categorias
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/cursos/videos`} className=''>
                                        Videos
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#podcast"
                        role="button"
                        aria-expanded="false"
                        aria-controls="podcast"
                        >
                            <div className='icon-nav-opt'>
                                <BiPodcast size={15}/>
                            </div>
                            <div className='text-option-nav'>
                                PodCast
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="podcast" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/podcast/create'} className=''>
                                        Adicionar
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/podcast`} className=''>
                                        Registrados
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/admin/podcast/categorias'} className=''>
                                        Categorias
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#webinars"
                        role="button"
                        aria-expanded="false"
                        aria-controls="webinars"
                        >
                            <div className='icon-nav-opt'>
                                <SiGooglemeet size={15}/>
                            </div>
                            <div className='text-option-nav'>
                                Webinars
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="webinars" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/webinars/create'} className=''>
                                        Adicionar
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/webinars`} className=''>
                                        Registrados
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#tutores"
                        role="button"
                        aria-expanded="false"
                        aria-controls="tutores"
                        >
                            <div className='icon-nav-opt'>
                                <GiTeacher size={15}/>
                            </div>
                            <div className='text-option-nav'>
                                Tutores
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="tutores" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/podcast/create'} className=''>
                                        Adicionar
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/podcast`} className=''>
                                        Registrados
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#carreira"
                        role="button"
                        aria-expanded="false"
                        aria-controls="carreira"
                        >
                            <div className='icon-nav-opt'>
                                <BiNetworkChart size={15}/>
                            </div>
                            <div className='text-option-nav'>
                                Carreira
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="carreira" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={`/admin/carreira/categorias`} className=''>
                                        Categorias
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/carreira/posts`} className=''>
                                        Posts
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/carreira/videos`} className=''>
                                        Videos
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    {
                        /*
                        <li>
                            <a
                            data-te-collapse-init
                            data-te-ripple-init
                            data-te-ripple-color="light"
                            href="#inpiracao"
                            role="button"
                            aria-expanded="false"
                            aria-controls="inpiracao"
                            >
                                <div className='icon-nav-opt'>
                                    <ImQuotesLeft size={15}/>
                                </div>
                                <div className='text-option-nav'>
                                    Inspiração
                                </div>
                                <div className='drop-arrow'>
                                    <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                                </div>
                            </a>
                            <div id="inpiracao" data-te-collapse-item className='!visible hidden collapse-items-more'>
                                <ul className='mt-2'>
                                    <li>
                                        <Link to={'/admin/podcast/create'} className=''>
                                            Adicionar
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/admin/podcast`} className=''>
                                            Registrados
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        */
                    }

                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#oportunidades"
                        role="button"
                        aria-expanded="false"
                        aria-controls="oportunidades"
                        >
                            <div className='icon-nav-opt'>
                                <MdWork size={15}/>
                            </div>
                            <div className='text-option-nav'>
                                Oportunidades
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="oportunidades" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/oportunity/create'} className=''>
                                        Adicionar
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/oportunity`} className=''>
                                        Registrados
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/oportunity/categories`} className=''>
                                        Categorias
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/admin/oportunity/empresas/'} className=''>
                                        Empresas
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#financa"
                        role="button"
                        aria-expanded="false"
                        aria-controls="financa"
                        >
                            <div className='icon-nav-opt'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M652 640q25 0 44.5-19.5T716 575q0-25-19.5-44T652 512q-25 0-44.5 19T588 575q0 26 19.5 45.5T652 640ZM538.478 763q-33.761 0-54.119-20.137Q464 722.726 464 689.94V463.239q0-33.522 20.359-53.38Q504.717 390 538.478 390h274.261q33.761 0 54.12 19.859 20.359 19.858 20.359 53.38V689.94q0 32.786-20.359 52.923T812.739 763H538.478ZM182.152 941.978q-26.599 0-47.365-20.265-20.765-20.266-20.765-47.865V278.152q0-26.697 20.765-47.533 20.766-20.837 47.365-20.837h595.696q27.697 0 48.033 20.837 20.337 20.836 20.337 47.533v54h-307.74q-57.043 0-94.684 37.655-37.642 37.655-37.642 93.432V690q0 56.043 37.642 93.446 37.641 37.402 94.684 37.402h307.74v53q0 27.587-20.337 47.859-20.336 20.271-48.033 20.271H182.152Z"/></svg>
                            </div>
                            <div className='text-option-nav'>
                                Finanças
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="financa" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/pagamentos/subscricoes'} className=''>
                                        Subscrições
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/pagamentos`} className=''>
                                        Pagamentos
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a
                        data-te-collapse-init
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        href="#logs"
                        role="button"
                        aria-expanded="false"
                        aria-controls="logs"
                        >
                            <div className='icon-nav-opt'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M203.522 1027q-21.203 0-35.711-14.63-14.507-14.623-14.507-35.826V846.218q0-20.247 14.388-35.232Q182.08 796 202.565 796h50.739V691.5h-50.739q-20.485 0-34.873-14.627-14.388-14.627-14.388-35.83V510.957q0-21.203 14.388-35.83Q182.08 460.5 202.565 460.5h50.739V356h-50.739q-20.485 0-34.873-14.986-14.388-14.985-14.388-35.232V175.217q0-20.964 14.507-35.59Q182.319 125 203.522 125h168.891q20.964 0 35.591 14.627 14.626 14.626 14.626 35.59v130.565q0 20.247-14.507 35.232Q393.616 356 373.37 356h-50.74v104.5h50.74q20.246 0 34.753 14.268 14.507 14.268 14.507 33.797v33.37h154.74v-33.37q0-19.529 14.626-33.797 14.627-14.268 35.591-14.268h168.891q21.203 0 35.83 14.627 14.627 14.627 14.627 35.83v130.086q0 21.203-14.627 35.83-14.627 14.627-35.83 14.627H627.587q-20.964 0-35.591-14.268-14.626-14.268-14.626-33.797v-33.37H422.63v33.37q0 19.529-14.507 33.797Q393.616 691.5 373.37 691.5h-50.74V796h50.74q20.246 0 34.753 14.986 14.507 14.985 14.507 35.232v130.326q0 21.203-14.626 35.826-14.627 14.63-35.591 14.63H203.522Z"/></svg>
                            </div>
                            <div className='text-option-nav'>
                                Logs
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="logs" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/logs/logins'} className=''>
                                        Logs
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a
                            data-te-collapse-init
                            data-te-ripple-init
                            data-te-ripple-color="light"
                            href="#settingsdrop"
                            role="button"
                            aria-expanded="false"
                            aria-controls="settingsdrop"
                        >
                            <div className='icon-nav-opt'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M546 976H414q-11 0-19.5-7T384 951l-16-101q-19-7-40-19t-37-25l-93 43q-11 5-22 1.5T159 836L93 719q-6-10-3-21t12-18l86-63q-2-9-2.5-20.5T185 576q0-9 .5-20.5T188 535l-86-63q-9-7-12-18t3-21l66-117q6-11 17-14.5t22 1.5l93 43q16-13 37-25t40-18l16-102q2-11 10.5-18t19.5-7h132q11 0 19.5 7t10.5 18l16 101q19 7 40.5 18.5T669 346l93-43q11-5 22-1.5t17 14.5l66 116q6 10 3.5 21.5T858 472l-86 61q2 10 2.5 21.5t.5 21.5q0 10-.5 21t-2.5 21l86 62q9 7 12 18t-3 21l-66 117q-6 11-17 14.5t-22-1.5l-93-43q-16 13-36.5 25.5T592 850l-16 101q-2 11-10.5 18t-19.5 7Zm-66-270q54 0 92-38t38-92q0-54-38-92t-92-38q-54 0-92 38t-38 92q0 54 38 92t92 38Z"/></svg>
                            </div>
                            <div className='text-option-nav'>
                                Configurações
                            </div>
                            <div className='drop-arrow'>
                                <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 50 50" width="20"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
                            </div>
                        </a>
                        <div id="settingsdrop" data-te-collapse-item className='!visible hidden collapse-items-more'>
                            <ul className='mt-2'>
                                <li>
                                    <Link to={'/admin/settings/general'} className=''>
                                        Geral
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/admin/settings/payments/methods'} className=''>
                                        Metodos de Pagamentos
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/settings/pacotes`} className=''>
                                        Pacotes
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default LeftNav;