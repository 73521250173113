import React, { useEffect, useRef, useState } from 'react'
import {
    Label,
    FormGroup
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Upload,
    Spin,
    Modal,
    Progress,
    message,
    Form,
    DatePicker,
    TimePicker
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Link, useNavigate } from "react-router-dom";
import { RcFile, UploadProps } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import api from '../../services/api';

import { useParams } from 'react-router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(customParseFormat);

const dateFormat = 'YYYY/MM/DD';

const { TextArea } = Input;

const antIcon = (
<LoadingOutlined
    style={{
    fontSize: 24,
    }}
    spin
/>
);

const OportunityView = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [cursos,setCursos] = useState([]);
    const [curso,setCurso] = useState('');
    const [loading,setloading] = useState(true);
    const [uploading,setUploading] = useState(false);
    const [percentage,setPercentage] = useState(0);
    const [video_info,set_video_info] = useState({});
    const [imageUrl,setImageUrl] = useState('');
    const [imageFile,setImageFile] = useState(null);
    const [video_file,set_video_file] = useState('');
    const [categorias,set_categorias] = useState([]);
    const [empresas,set_empresas] = useState("");
    const [typess,set_typess] = useState([
        {
            value: "Prencial",
            label: "Prencial"
        },
        {
            value: "Full Time",
            label: "Full Time"
        },
        {
            value: "Part Time",
            label: "Part Time"
        },
        {
            value: "Remoto",
            label: "Remoto"
        }
    ]);

    const params = useParams();

    const id = params.id;
    const video_id = params.video;

    const fileRef = useRef();

    const load = async () => {
        setloading(true);
        try{
			const response_video = await api.get(`/oportunity/${id}`);
            const response_types = await api.get('/oportunity/categorias');
            const response_empresas = await api.get('/oportunity/empresas', {
				params: { 
					search: "",
					orderBy: "desc"
				}
			});
            const data = response_video.data.data;
            const cats = response_types.data.data;
            const empresa = response_empresas.data.data;
            set_empresas(empresa.map((item) => ({
				value: item.id,
				label: item.nome
			})));
            set_categorias(cats.map((item) => ({
				value: item.id,
				label: item.name
			})));

            setImageUrl(data.imagem);

            form.setFieldsValue({
                date: dayjs(data.due_date)
            });
            form.setFieldsValue({
                qualifications: data.qualifications
            });
            form.setFieldsValue({
                qualifications_selected: data.qualifications_selected
            });
            form.setFieldsValue({
                responsibilities: data.responsibilities
            });
            form.setFieldsValue({
                link: data.link
            });
            form.setFieldsValue({
                title: data.title
            });
            form.setFieldsValue({
                descricao: data.descricao
            });
            form.setFieldsValue({
                category: data.category
            });
            form.setFieldsValue({
                tipo: data.tipo
            });
            form.setFieldsValue({
                visibilidade: data.status
            });
            form.setFieldsValue({
                empresa: data.empresa
            });
            setloading(false);
        }catch(error){
            console.log(error);
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageFile(file);
            setImageUrl(url);
        });
    };

    const upload_video = async (e) => {
        const file = e.target.files;
        const video_file = file[0];

        if(!video_file){
            return messageApi.open({
                type: 'error',
                content: 'Invalid',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }

        const Form = new FormData();
        Form.append('id',id);
        Form.append('video',video_file);

        setUploading(true);

        try{
            const config = {
                onUploadProgress: progressEvent => {
                    const progress = progressEvent.loaded / progressEvent.total * 100;
                    setPercentage(parseInt(progress));
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const response = await api.post('/cursos/video.upload', Form, config);
            const data = response.data.data;
            console.log(response.data.data);

            set_video_info(response.data.data);
            setImageUrl(data.frame.path);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
                duration: 10
            });
            setUploading(false);
        }catch(error){
            setUploading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    useEffect(() => {
        load();
    },[]);

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Oportunidade</h3>
            <Form
                autoComplete="off"
                form={form}
            >
                <Row gutter={12}>
                    <Col sm={24}>
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Título</Label>
                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <Input
                                    disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <TextArea disabled={true} rows={14} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Qualificações mínimas</Label>
                                <Form.Item
                                    name="qualifications"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your qualificações mínimas!',
                                        }
                                    ]}
                                >
                                    <TextArea disabled={true} rows={9} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Qualificações preferenciais</Label>
                                <Form.Item
                                    name="qualifications_selected"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your qualificações preferenciais!',
                                        }
                                    ]}
                                >
                                    <TextArea disabled={true} rows={9} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Responsabilidadess</Label>
                                <Form.Item
                                    name="responsibilities"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your qualificações responsabilidades!',
                                        }
                                    ]}
                                >
                                    <TextArea disabled={true} rows={9} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Link de condidatura</Label>
                                <Form.Item
                                    name="link"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your link!',
                                        }
                                    ]}
                                >
                                    <Input disabled={true} placeholder='youtube.com/watch?v=OL8NDU16Ex8'/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Categoria</Label>
                                <Form.Item
                                    name="category"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Categoria!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={categorias}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Empresa</Label>
                                <Form.Item
                                    name="empresa"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your empresa!',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={empresas}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Data de expiração</Label>
                                <Form.Item
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your data!',
                                        }
                                    ]}
                                >
                                    <DatePicker 
                                        style={{width: "100%"}} 
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Tipo</Label>
                                <Form.Item
                                    name="tipo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your tipo!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={typess}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Visibilidade</Label>
                                <Form.Item
                                    name="visibilidade"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Visibilidade!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Activo' },
                                            { value: 0, label: 'Inactivo' }
                                        ]}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col sm={12}>
                        <div>
                            <div className="w-full mb-3"> {/* conta corporativa*/}
                                <div className='video-background'>
                                    <div className='bold' style={{display: 'flex',justifyContent: 'center',alignItems: 'center',width: '100%', height: '100%', overflow: 'hidden'}}>
                                        { uploading
                                        ? 
                                        <Spin indicator={antIcon} /> 
                                        : 
                                        <div>
                                            {
                                                imageUrl ? <img style={{width:'100%',height:'100%',objectFit: 'cover'}} src={imageUrl}/> : 'Imagem'
                                            }
                                        </div> }
                                    </div>
                                    <input hidden type={'file'} onChange={handleChangeImage}></input>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Link to={`/admin/oportunity/edit/${id}`}>
                    <Button htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Editar</Button>
                </Link>
            </Form>
        </div>
    )
}

export default OportunityView;
