import React, { useEffect, useRef, useState } from 'react'
import { 
    Button, 
    Space, 
    Table, 
    Tag,
    Row,
    Col,
    Input,
    Select,
	message
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    FolderViewOutlined
} from '@ant-design/icons';
import api from '../../../services/api';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Empresas = () => {
	const MySwal = withReactContent(Swal);
	const [messageApi, contextHolder] = message.useMessage();
	const [data,setData] = useState([]);
	const [search,setSearch] = useState('');
	const [tipo,setTipo] = useState('');
    const [loading,set_loading] = useState([]);
	const [typess,set_user_types] = useState([
        {
            value: "prencial",
            label: "Prencial"
        },
        {
            value: "fulltime",
            label: "Full Time"
        },
        {
            value: "parttime",
            label: "Part Time"
        },
        {
            value: "remoto",
            label: "Remoto"
        }
    ]);

    const sub_ref = useRef();

	const load = async () => {
        set_loading(true);
		try{
			//const response_types = await api.get('/oportunity/types');
            const response = await api.get('/oportunity/empresas', {
				params: { 
					search,
					orderBy: "desc"
				}
			});
            const users = response.data.data;
			//const types = response_types.data.data;
			setData(users);
            set_loading(false);
        }catch(error){
            set_loading(false);
            console.log(error);
        }
	}

	const filter = async () => {
		try{
            const response = await api.get('/oportunity/empresas', {
				params: { 
					search,
					orderBy: "desc"
				}
			});
            const users = response.data.data;
			setData(users);
        }catch(error){
            console.log(error);
        }
	}

	const handle_search_input = (e) => {
		const value = e.target.value
		setSearch(value);
	}

	useEffect(() => {
		load();
	},[]);

	useEffect(() => {
		filter();
	},[search]);

	const handleChange = (value) => {
		setTipo(value);
	};

	const showPopconfirmDelete = (id) => {
		Swal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

	const handleDelete = async (id) => {
		try{
            const response = await api.delete(`/oportunity/empresas/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const columns = [
		{
			title: 'imagem',
			render: (row) => {
                return(
                      <div className='modulo-capa'>
                          <img src={row.imagem}></img>
                      </div>
                )
              }
		},
        {
          title: 'Nome',
          dataIndex: 'nome',
          key: 'nome',
        },
        {
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
        {
          title: 'contacto',
          dataIndex: 'contacto',
          key: 'contacto',
        },
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        },
        {
          title: 'Ação',
          key: 'action',
          render: (row) => (
            <Space size="middle">
                <Link to={`/admin/oportunity/empresas/view/${row.id}`}>
					<Button shape="circle">
                        <FolderViewOutlined />
					</Button>
				</Link>

                <Link to={`/admin/oportunity/empresas/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>

				<Button onClick={() => showPopconfirmDelete(row.id)} danger shape="circle">
					<DeleteOutlined />
				</Button>
            </Space>
          ),
        },
    ];

    return (
        <div className='bg-white m-5 p-7'>
			{contextHolder}
            <div className='flex-ttp-items mb-4'>
                <h3 className='header-sflix text-dark'>Empresas</h3>
                <Link to={`/admin/oportunity/empresas/create`}>
                    <Button className='bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <div className='mb-4'>
                <Row gutter={8}>
                    <Col span={8}>
                        <Input onChange={handle_search_input} placeholder="Pesquisar" />
                    </Col>
                </Row>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
                loading={loading}
            />
        </div>
    )
}

export default Empresas;